import { ReactElement } from 'react';
import { Link } from '..';
import styles from './styles.module.scss';

type DesktopMenuProps = {
  items: {
    label: string | ReactElement;
    to: string;
  }[];
};

const DesktopMenu = (props: DesktopMenuProps) => {
  return (
    <div className={styles.root}>
      <ul>
        {props.items.map(({ label, to }) => (
          <li key={`${label}|${to}`}>
            <Link to={to}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DesktopMenu;
