import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const IconButton = ({ children, className, onClick }: Props) => {
  return (
    <button className={cx(styles.root, className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default IconButton;
