import { useWindowDimensions } from 'hooks';
import { DesktopMenu, MobileMenu, Link } from '.';
import styles from './styles.module.scss';

const ITEMS = [
  { label: 'About Us', to: '/#about-us' },
  { label: 'Meet Our Craftsman', to: '/#meet-our-craftsman' },
  // { label: 'Shop', to: '/shop' },
  { label: 'Contact Us', to: '/#contact-us' },
];

const Header = () => {
  const { width } = useWindowDimensions();
  const Menu = width >= 1280 ? DesktopMenu : MobileMenu;

  return (
    <header className={styles.header}>
      <nav>
        <Link to="/">
          <img
            className={styles.logo}
            alt="Salty Cat Woodworks Logo"
            src="/logo.svg"
          />
        </Link>
        <Link to="/">
          <h1>Salty Cat Woodworks</h1>
        </Link>
        <Menu items={ITEMS} />
      </nav>
    </header>
  );
};

export default Header;
