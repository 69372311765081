import { useEffect } from 'react';
import { useLocation } from 'wouter';

// Initial page view will fire from index.html...don't want to double fire it.
let initialLoadComplete = false;

const useHubSpotTrackPageView = () => {
  const [location] = useLocation();

  useEffect(() => {
    if (initialLoadComplete) {
      const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
      _hsq.push(['setPath', location]);
      _hsq.push(['trackPageView']);
    } else {
      initialLoadComplete = true;
    }
  }, [location]);
};

export default useHubSpotTrackPageView;
