import cx from 'classnames';
import { Parallax } from 'components';
import styles from './styles.module.scss';

type ContentSectionProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
  id?: string;
  image?: React.ReactNode;
  parallax?: {
    image: string;
    opacity?: number;
  };
};

const ContentSection = ({
  title,
  children,
  className,
  id,
  image,
  parallax,
}: ContentSectionProps) => {
  const contentSection = (
    <div id={id} className={cx(styles.contentSection, className)}>
      {image && <div className={styles.imageWrapper}>{image}</div>}
      <div className={styles.content}>
        <h2>{title}</h2>
        <div>{children}</div>
      </div>
    </div>
  );

  if (parallax)
    return (
      <Parallax
        image={parallax.image}
        opacity={parallax.opacity}
        className="fill"
      >
        {contentSection}
      </Parallax>
    );

  return contentSection;
};

export default ContentSection;
