import { ReactElement, useState } from 'react';
import { IconButton } from 'components';
import { Link } from '..';
import styles from './styles.module.scss';

type Props = {
  items: {
    label: string | ReactElement;
    to: string;
  }[];
};

const MobileMenu = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.root}>
      <IconButton onClick={() => setOpen(!open)}>
        <img alt="Menu" src="/menu.svg" />
      </IconButton>
      {open && (
        <div className={styles.overlay}>
          <IconButton className={styles.close} onClick={() => setOpen(!open)}>
            <img alt="Menu" src="/close.svg" />
          </IconButton>
          <div className={styles.content}>
            <ul>
              {props.items.map(({ label, to }) => (
                <li key={`${label}|${to}`} onClick={() => setOpen(false)}>
                  <Link to={to} onClick={() => setOpen(false)}>
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
