import cx from 'classnames';
import { Link } from 'wouter';
import { ScrollLink } from 'components';
import styles from './styles.module.scss';

type PrimaryButtonProps = {
  appearance?: 'primary' | 'secondary';
  children?: React.ReactNode;
  className?: string;
  to?: string;
  scrollTo?: string;
};

const PrimaryButton = ({
  appearance,
  children,
  className,
  to,
  scrollTo,
}: PrimaryButtonProps) => {
  const button = (
    <button
      className={cx(styles.button, className, {
        [styles.primary]: !appearance || appearance === 'primary',
        [styles.secondary]: appearance === 'secondary',
      })}
    >
      {children}
    </button>
  );

  if (to) return <Link to={to}>{button}</Link>;
  if (scrollTo) return <ScrollLink to={scrollTo}>{button}</ScrollLink>;
  return button;
};

export default PrimaryButton;
