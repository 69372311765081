import { useEffect } from 'react';

type HubSpotFormOptions = {
  portalId: string;
  formId: string;
  target: string;
};

const useHubSpotForm = (options: HubSpotFormOptions) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      (window as any)?.hbspt?.forms?.create({
        portalId: '4540745',
        formId: '7638340b-5ec1-4dae-bacd-48bfbe25d32e',
        target: '.contact-us-form',
      });
    });
  }, [options]);
};

export default useHubSpotForm;
