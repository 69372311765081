import { ReactElement } from 'react';
import { Link as RouterLink, useLocation } from 'wouter';
import { ScrollLink } from 'components';

type LinkProps = {
  children: ReactElement | string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  to: string;
};

const Link = (props: LinkProps) => {
  const [location] = useLocation();
  const [path, hash] = props.to.split('#');

  return path === location && hash ? (
    <ScrollLink to={hash} onClick={props.onClick}>
      {props.children}
    </ScrollLink>
  ) : (
    <RouterLink to={props.to} onClick={props.onClick}>
      {props.children}
    </RouterLink>
  );
};

export default Link;
