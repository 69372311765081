import { Footer, Header, Router } from 'components';
import { useHubSpotTrackPageView } from 'hooks';
import styles from './styles.module.scss';

const App = () => {
  useHubSpotTrackPageView();

  return (
    <div className={styles.app}>
      <Header />
      <main className="fill">
        <Router />
      </main>
      <Footer />
    </div>
  );
};

export default App;
