import cx from 'classnames';
import styles from './styles.module.scss';

type ParallaxProps = {
  children?: React.ReactNode;
  className?: string;
  image: string;
  opacity?: number;
  style?: any;
};

const Parallax = ({
  children,
  className,
  image,
  opacity,
  style,
}: ParallaxProps) => {
  const ParallaxWrapper = ({ children }: { children: React.ReactNode }) => (
    <div
      className={cx(styles.parallax, className)}
      style={{
        ...style,
        backgroundImage: `url('${process.env.PUBLIC_URL}${image}`,
      }}
    >
      {children}
    </div>
  );

  if (opacity)
    return (
      <ParallaxWrapper>
        <div
          className={styles.opacity}
          style={{ backgroundColor: `rgba(255, 255, 255, ${opacity})` }}
        >
          {children}
        </div>
      </ParallaxWrapper>
    );

  return <ParallaxWrapper>{children}</ParallaxWrapper>;
};

export default Parallax;
